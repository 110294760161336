<template>
  <div class="modal team">
    <div class="box one mHeader flexB">
      <h1>{{ $t("user-home-user-version-notice-register-title")}}</h1>
      <a @click="close"><i class="fas fa-times"></i></a>
    </div>
    <div class="box one mBody">
      <div class="tableWrap">
        <colgroup>
          <col style="width: 15%" />
          <col style="width: 35%" />
          <col style="width: 15%" />
          <col style="width: 35%" />
        </colgroup>
        <table class="appInfo">
          <tr>
            <th>{{$t("user-home-user-version-app-type")}}</th>
            <td>
              <select v-model="appType">
                <option disabled value>{{ $t("select-box-default-choose") }}</option>
                <option value="ViewApp">{{$t("user-home-user-version-app-type-viewer")}}</option>
                <option value="ControllerApp">{{$t("user-home-user-version-app-type-controller")}}</option>
              </select>
            </td>
            <th>{{ $t("user-home-user-version-app-version") }}</th>
            <td>
              <input type="text" style="width: 100%; font-size: 1.2rem; margin-bottom: 0;" v-model="version"
                     :placeholder="[$t('user-home-user-version-notice-please-input-version')]" />
            </td>
          </tr>
          <tr>
            <th>{{$t("user-home-user-version-app-is-open")}}</th>
            <td colspan="3">
              <label style="font-size: 1.4rem; line-height: 36px;">
                <input
                    type="checkbox"
                    checked
                    v-model="isOpen"
                    true-value=true
                    :false-value=false
                    @change="handleShowType"
                />{{$t("user-home-user-version-app-open")}}
              </label>
            </td>
          </tr>
          <tr>
            <th>{{$t("user-home-user-version-app-release-note")}}</th>
            <td colspan="3">
              <textarea style="width: 100%; height: 200px; padding: 5px 10px; font-size: 1.2rem; border: 1px solid #d3d3d3; margin: 5px 0; resize: none;"
                        v-model="releaseNote" :placeholder="[$t('user-home-user-version-app-please-input-release-note')]"></textarea>
            </td>
          </tr>
        </table>
      </div>
      <div>
        <template>
          <div v-if="!editMode" class="container" style="margin-top: 10px;">
            <!-- 파일 업로드 -->
            <input v-if="fileList.length === 0" type="file" ref="fileInput" class="file-upload-input" @change="onFileChange" multiple>
            <!-- 업로드된 리스트 -->
            <div class="file-upload-list">
              <div class="file-upload-list__item" v-for="(data, i) in fileList" :key="i">
                <!-- 업로드된 리스트 -->
                <div class="file_upload_form">
                  <span >{{ data.name }}</span>
                  <img src="@/assets/images/icon_svg/ic_delete_gr.svg" alt="icon" style="width: 1.5rem; height: 1.5rem; margin-left: 12px;" @click="handleRemove(i)"/>
                </div>
              </div>
            </div>
          </div>
          <div v-if="editMode" class="container" style="margin-top: 10px;">
            <!-- 파일 업로드 -->
            <input v-if="!fileName && fileListToModify.length === 0" type="file" ref="fileInput" class="file-upload-input" @change="onFileChange" multiple>
            <!-- 업로드된 리스트 -->
            <div class="file-upload-list">
              <div v-if="filePath && fileName" class="file-upload-list__item">
                <div class="file_upload_form">
                  <span>{{ fileName }}</span>
                  <img src="@/assets/images/icon_svg/ic_delete_gr.svg" alt="icon" style="width: 1.5rem; height: 1.5rem; margin-left: 12px;" @click="deleteAttachedFile(versionAppLogId, fileName)"/>
                </div>
              </div>
              <div class="file-upload-list__item" v-for="(data, im) in fileListToModify" :key="im">
                <!-- 업로드된 리스트 -->
                <div class="file_upload_form">
                  <span>{{ data.name }}</span>
                  <img src="@/assets/images/icon_svg/ic_delete_gr.svg" alt="icon" style="width: 1.5rem; height: 1.5rem; margin-left: 12px;" @click="handleRemove(im)"/>
                </div>
              </div>
            </div>
          </div>
        </template>

      </div>
      <div class="buttonWrap">
        <button v-if="editMode" class="point large" @click="patch">{{ $t("btn-data-patch") }}</button>
        <button v-else class="point large" @click="submit">{{ $t("btn-register") }}</button>
        <button class="large margin6" @click="close">{{ $t("btn-cancel") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  createVersionApp,
  createVersionAppFileUpload,
  deleteVersionAppAttachedFile,
  fetchVersionAppInfo, updateVersionAppInfo
} from "@/api/version";

export default {
  props : {
    memo : {
      type : String,
      default : null,
      file: "",
      fileSrc:"",
      fileList:[],
    },
    versionAppLogId:{
      type:String,
      default: null,
    }
  },
  data() {
    return {
      appType : "",
      version: "",
      isOpen: true,
      releaseNote: null,
      fileList: [], //수정시: 기존 파일만 여기에 담는다.
      fileListToModify: [], //수정시 새로 추가된 파일만 여기에 담는다.
      fileName: "",
      filePath: null,
      editMode: false,
    };
  },
  mounted() {
    if(this.versionAppLogId != null){
      this.editMode = true;
      this.getVersionAppInfo();
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    handleShowType(e){
      this.isOpen = e.target.checked;
    },
    submit() {
      if(this.appType === ""){
        return alert(this.$t("user-home-user-version-app-please-select-app-type"));
      }
      if(this.version === ""){
        return alert(this.$t("user-home-user-version-app-please-input-version"));
      }

      let params = {
        appType: this.appType,
        version: this.version,
        isOpen: this.isOpen,
        releaseNote: this.releaseNote
      };

      createVersionApp(params).then((res)=>{
        if (res.data.result === 0){
          this.uploadFile(this.fileList, res);
        }else{
          let message = res.data.message;
          alert(message);
          this.close();
        }
      });
    },
    handleRemove (index) {
      if(this.editMode){
        this.fileListToModify.splice(index, 1)
      }else{
        this.fileList.splice(index, 1)
      }
    },
    deleteAttachedFile(versionAppLogId, fileName){
      if(confirm(this.$t("site-view-summary-system-notice-ask-delete-file")+"\n"+fileName)){
        deleteVersionAppAttachedFile(versionAppLogId).then((res)=>{
          if (res.data.result === 0){
            alert(this.$t("alert-message-delete"));
            this.fileName = null;
            this.filePath = null;
          }
        });
      }
    },
    onFileChange(e){
      this.file = e.target.files[0];

      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = (e) => {
        this.fileSrc = e.target.result;
      };
      if(this.editMode){
        this.fileListToModify.push(this.file);
      }else{
        this.fileList.push(this.file);
      }
    },
    getVersionAppInfo(){
      fetchVersionAppInfo(this.versionAppLogId).then((res)=>{
        this.appType = res.data.data.appType;
        this.version = res.data.data.version;
        this.isOpen = res.data.data.isOpen;
        this.releaseNote = res.data.data.releaseNote;
        this.fileName = res.data.data.fileName;
        this.filePath = res.data.data.filePath;
      });
    },
    patch(){
      if(this.appType === ""){
        return alert(this.$t("user-home-user-version-app-please-select-app-type"));
      }
      if(this.version === ""){
        return alert(this.$t("user-home-user-version-app-please-input-version"));
      }
      let params = {
        appType: this.appType,
        version: this.version,
        isOpen: this.isOpen,
        releaseNote: this.releaseNote
      };

      updateVersionAppInfo(this.versionAppLogId, params).then((res)=>{
        if (res.data.result === 0){
          this.uploadFile(this.fileListToModify, res);
        }else{
          let message = res.data.message;
          alert(message);
          this.close();
        }
      });
    },
    uploadFile(listOfFiles, versionAppInfo){
      if(listOfFiles.length > 0){
        let fileUploadData = new FormData();

        fileUploadData.append("versionAppLogId", versionAppInfo.data.data.versionAppLogId);
        listOfFiles.forEach( (eachFile) => {
          fileUploadData.append("files", eachFile);
        });
        let uploadFailMessage = "";

        createVersionAppFileUpload(fileUploadData).then((fileUploadRes) => {
          let uploadResultData = fileUploadRes.data.data;
          uploadResultData.forEach((eachRes) => {
            if(eachRes.content == null){
              uploadFailMessage = uploadFailMessage+ "["+eachRes.fileName+", "+eachRes.message+"]\n";
            }
          });
          alert(this.$t("alert-message-data-save"));
          if(uploadFailMessage !== ""){
            alert(this.$t("590")+" "+uploadFailMessage);
          }
          this.close();
          this.$router.go();
        });
      }else{
        alert(this.$t("alert-message-data-save"));
        this.close();
        this.$router.go();
      }
    },
  }
};
</script>
<style lang="scss">
 .tableWrap{
   table.appInfo{
     th{
       background: #ebebeb;
       text-align: center;
       border-bottom: 1px solid #ebebeb;
     }
     td{
       background : white;
     }&:hover{
       background: transparent;
     }

     th:nth-child(1),
     th:nth-child(3),
     th:nth-child(5) {
       width: 110px;

     }
   }
 }
</style>

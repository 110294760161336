<template>
  <div>
    <div class="title flexB" style="display: block;">
      <h1>{{ $t("top-select-total-view-dashboard") }}</h1>
      <div>
<!--        <span
        >{{ moment().format("YYYY.MM.DD(dd) HH:mm") }} {{ $t("data-today") }}</span
        >-->
        <button class="medium" @click="handleRefresh">{{ $t("btn-refresh") }}</button>
<!--        <button class="small margin6"  style="background: white;" @click="handlePop">
          {{ $t("btn-new-browser") }}
        </button>-->
      </div>
    </div>
    <div class="contents overall"> <!--style="min-height: calc(100vh - 590px);" >-->
      <div class="flexB boxCol2-2" :style="{ 'height': typeChangeUpShowHeight +'px'}" >
        <div class="box cntWrap" style="margin-right: 15px;">
          <h2 class="bold">{{ $t("overall-all-count-title") }}</h2>
          <div class="summary flex" style="flex-wrap: wrap; flex-direction: column; padding: 16px; height: calc( 100% - 32px); justify-content: center;">
              <div class="flexB" style="
                   height: calc( 33.33% - 10px);
                   justify-items: center;
                   margin-bottom: 5px;
                   padding: 13px 16px;
                   border-radius: 4px;
                   border: 1px solid #ebebeb;
                   background: #f4f4f4;">
                <div class="boxin">
                  <img src="@/assets/images/icon_svg/ic_ds_all_site_b.svg" alt="icon" />
                  <b> {{ $t("overall-data-event") }}</b>
                </div>
                <p>
              <span>{{ eventTotal || 0 }}</span
              ><b> {{ $t("overall-data-event-ea") }}</b>
                </p>
              </div>
            <!--참여 디바이스 개수(디자인 변경)-->
                <!--<div class="flexB" style="
                   height: calc( 33.33% - 10px);
                   justify-items: center;
                   margin-top: 5px;
                   margin-bottom: 5px;
                   padding: 13px 16px;
                   border-radius: 4px;
                   border: 1px solid #ebebeb;
                   background: #f4f4f4;">
              <div class="boxin">
                <img src="@/assets/images/icon_svg/ic_ds_all_drone.svg" alt="icon" />
                <b> {{ $t("overall-data-device") }}</b>
              </div>
              <p>
              <span>{{ deviceCount || 0 }}</span
              ><b> {{ $t("overall-data-device-ea") }}</b>
              </p>
            </div>-->
            <div class="flex" style=" height: calc( 33.33% - 10px); justify-content: center; margin: 6px 0;">
              <div class="wid50" style="margin-right: 16px; ">
                <div class="flexB">
                  <img src="@/assets/images/icon_svg/ic_ds_all_drone.svg" alt="icon" />
                  <p>
                    <span>{{ deviceTotal || 0 }}</span>
                    <b> {{ $t("overall-data-device-ea") }}</b>
                  </p>
                </div>
                <b style="font-size: 1.3rem;"> {{ $t("overall-data-register-device") }}</b>
              </div>
              <div class="wid50" style="height: 100%;">
                <div class="flexB">
                  <img src="@/assets/images/icon_svg/ic_ds_all_member.svg" alt="icon" />
                  <p>
                    <span>{{ memberCount || 0 }}</span>
                    <b> {{ $t("overall-data-member-ea") }}</b>
                  </p>
                </div>
                <b style="font-size: 1.3rem" >{{ $t("overall-data-member") }}</b>
              </div>
            </div>
            <div class="flex" style=" height: calc( 33.33% - 10px);justify-content: center;  margin: 6px 0;">
              <div class="wid50" style="margin-right: 16px;">
                <div class="flexB boxin1-1">
                  <img src="@/assets/images/icon_svg/ic_ds_all_live.svg" alt="icon"/>
                  <p>
                    <span>{{ liveTotal || 0 }}</span>
                    <b> {{ $t("data-case-ea") }}</b>
                  </p>
                </div>
                <b style="font-size: 1.3rem"> {{ $t("top-select-live-data-allocation") }} </b>
              </div>
              <div class="wid50">
                <div class="flexB boxin1-1">
                  <img src="@/assets/images/icon_svg/ic_ds_all_vod.svg" alt="icon" />
                  <p>
                    <span>{{ vodTotal || 0 }}</span>
                    <b> {{ $t("overall-data-default-ea") }}</b>
                  </p>
                </div>
                <b style="font-size: 1.3rem"> {{ $t("overall-data-cnt-log-vod") }}<span style="color: transparent">개 </span></b>
              </div>
            </div>

          </div>
        </div>
        <div class="box noticeList flexB">
          <div class="infoWrap" style="width:100%; height: 100%;">
            <div class="flexB">
              <h2 class="bold">
                {{ $t("user-home-user-system-notice") }}
              </h2>
              <button class="small redBtn" style="height: 2.5rem; line-height: 2.5rem;" @click="handleTab"> 작성하기 </button>
            </div>
            <div class="tableBox" style="height: calc(100% - 30px); overflow-y: auto;">
              <table class="noticeTable" >
                <colgroup>
                  <col style="width: 10%;"/>
                  <col style="width: 70%"/>
                  <col style="width: 20%"/>
                </colgroup>
                <tr>
                  <th>No</th>
                  <th>{{ $t("user-home-user-system-notice-title") }}</th>
                  <th>{{ $t("user-home-user-system-notice-date") }}</th>
                </tr>
                <!--                <tr
                                    v-for="(data, i) in 11"
                                    :key="i"
                                    @click="handleDetail"
                                >
                                  <td>{{ 11 - (size * currentPage + i) }}</td>
                                  <td>2022.08.15 v1.0 버전 업데이트 안내입니다.</td>
                                  <td>2021.02.02.19:30:00</td>
                                </tr>-->
                <tr v-for="(data, i) in noticeAppList" :key="i" @click="handleDetail(data.noticeAppId)">
                  <td>{{ noticeAppTotal - (noticeAppCurrentPage * noticeAppPageSize + i) }}</td>
                  <td>{{data.title}}</td>
                  <td>{{ data ? moment(data.registerDate).format("YYYY-MM-DD HH:mm:SS") : "" }}</td>
                </tr>
                <tr v-if="noticeAppList.length == 0">
                  <td colspan="3" style="width: 100%; height:42px; background: white;">
                    <span>{{ $t("data-none-result") }}</span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="flexB boxCol2-2" :style="{ 'height': typeChangeDownShowHeight + 'px' }">
        <div class="box" style="margin-right: 15px;">
          <h2 class="bold">
            {{ $t("overall-device-list-title") }}
            <router-link :to="`/${accountId}/device/deviceList`">
              <span class="more right">
                {{ $t("btn-txt-more") }}
                <img src="@/assets/images/icon_svg/arrow_right.svg" alt="icon"/>
              </span>
            </router-link>
          </h2>
          <div class="droneWrap" style="overflow: auto;" :style="{'height' : typeChangeDownShowHeight - ( 32 + 30 + 16 )  + 'px' }">
            <div class="drone flex" v-for="(data, i) in deviceList" :key="i"  style="margin-bottom: 8px;">
              <!--              <div v-if="deviceList[index - 1] == undefined || deviceList[index - 1].isLoad == false">
                              <div class="flexB" style="justify-content: center;">
                                <div>
                                  <p class="bold" >{{ $t("no-live-channel") }}</p>
                                </div>
                              </div>
                            </div>-->

              <div class="thumBox">
                <img
                    :src="getDeviceImg(data)"
                    alt="썸네일"
                    class="deviceImg"
                />
              </div>
              <div class="droneBox flex" style="width: 100%; margin-left: 16px; padding-left: 14px; border-bottom: 1px solid #ebebeb;">
                <div class="dv_title">
                  <p class="bold">{{ data.deviceId.substring(0, 6) }}</p>
                </div>
                <div class="dv_contents">
                  <div class="flex dvCont">
                    <p class="drData bold">{{data.manufacturerName? data.manufacturerName : "N/A"}}</p>
                    <p class="drData bold">{{data.modelName? data.modelName : "N/A"}}</p>
                    <p class="drData bold">{{data.serial? data.serial : "N/A"}}</p>
                  </div>
                  <div class="flex dvCont">
                    <p class="drData">{{data.deviceName? data.deviceName : "N/A"}}</p>
                    <p class="drData">{{data.registerUserDeptName? data.registerUserDeptName : "N/A"}}</p>
                    <p class="drData">{{data.ownerName? data.ownerName : "N/A"}}</p>
                    <p class="drData">{{data.registerDate == null? "-" : moment(data.registerDate).format("YYYY.MM.DD")}}</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="box noticeList flexB">
          <div class="infoWrap" style="width:100%; height: 100%;">
            <div class="flexB">
              <h2 class="bold">
                {{ $t("user-home-user-system-board-download") }}
              </h2>
              <button class="small redBtn" style="height: 2.5rem; line-height: 2.5rem;" @click="handleVersionTab"> 작성하기 </button>
            </div>
            <div class="tableBox" style="height: calc(100% - 30px); overflow-y: auto;">
              <table class="noticeTable" >
                <colgroup>
                  <col style="width: 10%;"/>
                  <col style="width: 20%;"/>
                  <col style="width: 20%;"/>
                  <col style="width: 15%;"/>
                  <col style="width: 15%;"/>
                  <col style="width: 20%;"/>
                </colgroup>
                <tr>
                  <th>No</th>
                  <th>{{ $t("user-home-user-version-app-type") }}</th>
                  <th>{{ $t("user-home-user-version-app-version") }}</th>
                  <th>{{ $t("user-home-user-version-app-is-latest") }}</th>
                  <th>{{ $t("user-home-user-version-app-is-open") }}</th>
                  <th>{{ $t("user-home-user-system-notice-date") }}</th>
                </tr>
<!--                <tr
                    v-for="(data, i) in 11"
                    :key="i"
                    @click="handleVersionDetail"
                >
                  <td>{{ 11 - (size * currentPage + i) }}</td>
                  <td>2022.08.15 v1.0 버전 업데이트 안내입니다.</td>
                  <td>2022.08.15 v1.0 버전 업데이트 안내입니다.</td>
                  <td>2022.08.15 v1.0 버전 업데이트 안내입니다.</td>
                  <td>2022.08.15 v1.0 버전 업데이트 안내입니다.</td>
                  <td>2021.02.02.19:30:00</td>
                </tr>-->
                <tr v-for="(data, i) in versionAppLogList" :key="i" @click="handleVersionDetail(data.versionAppLogId)">
                  <td>{{ versionAppLogTotal -  i }}</td>
                  <td>
                    {{
                      data.appType == "ViewApp"
                          ? $t("user-home-user-version-app-type-viewer")
                          :data.appType == "ControllerApp"
                              ? $t("user-home-user-version-app-type-controller")
                              : "-"
                    }}
                  </td>
                  <td>{{data.version}}</td>
                  <td>
                    {{
                      data.isLatest == true
                          ? $t("user-home-user-version-app-answer-yes")
                          : $t("user-home-user-version-app-answer-no")
                    }}
                  </td>
                  <td>
                    {{
                      data.isOpen == true
                          ? $t("user-home-user-version-app-answer-yes")
                          : $t("user-home-user-version-app-answer-no")
                    }}
                  </td>
                  <td>{{ data ? moment(data.releasedDate).format("YYYY-MM-DD HH:mm:SS") : "" }}</td>
                </tr>
                <tr v-if="versionAppLogList.length == 0">
                  <td colspan="6" style="width: 100%; height:42px; background: white;">
                    <span>{{ $t("data-none-result") }}</span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
    <transition name="fade">
      <div v-if="visible == 1" class="dim on" >
        <SystemNotice @close="close" :noticeAppId="this.noticeAppId" />
      </div>
      <div v-if="visible == 2" class="dim on">
        <SystemNoticeDetail @close="close" @patch="patch" :noticeAppId="this.noticeAppId" />
      </div>
    </transition>
    <transition name="fade">
      <div v-if="visible == 3" class="dim on">
        <VersionNotice @close="close" :versionAppLogId="this.versionAppLogId" />
      </div>
      <div v-if="visible == 4" class="dim on">
        <VersionNoticeDetail @close="close" @patch="patch" :versionAppLogId="this.versionAppLogId" />
      </div>
    </transition>

  </div>
</template>
<style scoped>
.title{margin-top: 0;}

.live-video {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
}
.drData{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
.wd3{
  width: 30%;
}
.wd2{
  width: 20%;
}
.mainMenu_admin{display: block !important;}
</style>
<script>
import moment from "moment";
import { baseUrl } from "@/config/env";
import { mapState } from "vuex";

import {fetchEventList, fetchEventJoinList} from "@/api/dashboard";

import { fetchNoticeAppList } from "@/api/event";

import { fetchStatisticsWorkerList, fetchStatisticsEventList, fetchStatisticsDeviceList, } from "@/api/statistics";
import { getDeviceImgUrl, getDeviceImgDefault, fetchDeviceList, } from "@/api/device";
import { format } from "@/mixins/format";

import SystemNotice from "@/components/pop/SystemNotice";
import SystemNoticeDetail from "../../components/pop/SystemNoticeDetail";

import VersionNotice from "@/components/pop/VersionNotice";
import VersionNoticeDetail from "../../components/pop/VersionNoticeDetail";

import {fetchVersionAppLogList} from "@/api/version";
import {fetchVodList} from "@/api/contents";
import { fetchChannelAssignList } from "@/api/channel";

export default {
  components: {
    SystemNotice,
    SystemNoticeDetail,
    VersionNotice,
    VersionNoticeDetail,
  },
  mixins: [format],
  name: "Main",
  data() {
    return {
      index: 0,
      accountId: localStorage.getItem("accountId"),
      chId: null,
      baseUrl: baseUrl,
      moment: moment,

      //화면 사이즈 조절 region
      windowHeight: window.innerHeight,
      typeChangeUpShowHeight: 0,
      typeChangeDownShowHeight: 0,
      typeMaxUpShowHeight : 360,
      //화면 사이즈 조절 endregion
      eventCount: 0,
      deviceCount: 0,
      deviceList : [],

      //시스템 공지
      visible: false,
      memo: "",
      noticeAppList:[],
      noticeAppTotal: 0,
      noticeAppCurrentPage: 0,
      noticeAppPageSize: 20,
      noticeAppId: "",
      vodTotal: 0,

      //앱 버전 정보
      versionAppLogList: [],
      versionAppLogTotal: 0,
      versionAppLogId : "",

      visibleList:[],
      liveCount: 0,
      memberCount: 0,
      trafficValue: 0,
      eventList: [],
      eventTotal: 0,
      workerList: [],
      controller: [],

      liveTotal: 0,
      pointTotal: 0,
      deviceTotal: 0,
      support: [],
      event: [],
      traffic: [],

      chartEvent : [],
      chartDevice : [],
      chartSupport : [],
    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 1, sub: 0 });
    window.addEventListener('resize', this.handleResize);
    this.typeChangeUpShowHeight = (this.windowHeight - 90) * 0.5;
    this.typeChangeDownShowHeight = (this.windowHeight - 90) * 0.5;

  },
  async mounted() {
    await this.callFunctionsWhenMounted();
    this.getCount();

    // 1. ResizeObserver 객체 만들기
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        const {width, height} = entry.contentRect;
        width;
        height;
        this.$refs.map.setLayout();
      }
    });

    // 2. 감지할 요소 추가하기
    observer.observe(this.$refs.map_div);

  },
  mount(){
  },
  watch : {
    currentLang(value){
      this.initMapData(value);
    },
    typeChangeUpShowHeight(value){
      var upHeight = value;
      if(upHeight != this.typeMaxUpShowHeight) {
        upHeight = this.typeMaxUpShowHeight;
      }
      this.typeChangeUpShowHeight = upHeight;
    },
  },
  computed: {
    ...mapState({
      gnbVisible: state => state.store.gnbVisible,
      currentLang: state => state.store.currentLang,
    }),
  },
  methods: {
    getCount() {
      let workerParams = {
        pageSize : 1,
        eventStatuses : "Open",
        workerTypes : 'Worker,Controller'
      }
      fetchEventJoinList('worker',workerParams).then((res) => {
        if(res.data.result == 0) {
          this.memberCount = res.data.data.total
        }
      });

    },
    handleTab() {
      this.visible = 1;
      this.noticeAppId = null;
    },
    handleVersionTab() {
      this.visible = 3;
      this.versionAppLogId = null;
    },
    handleDetail(noticeAppId) {
      this.noticeAppId = noticeAppId;
      this.visible = 2;
    },
    handleVersionDetail(versionAppLogId) {
      this.versionAppLogId = versionAppLogId;
      this.visible = 4;
    },
    patch(id){
      if(this.visible == 4){
        this.visible = 3;
        this.versionAppLogId = id;
      }else{
        this.visible = 1;
        this.noticeAppId = id;
      }
    },
    close() {
      this.visible = false;
    },
    getDeviceImg(data){
      return data.defaultIsImage ? getDeviceImgDefault(data.manufacturerId, data.modelId) : getDeviceImgUrl(this.accountId, data.deviceId) + '?date='+(new Date)
    },

    //페이지 리사이즈
    handleResize(){
      this.windowHeight = window.innerHeight;
      this.typeChangeUpShowHeight = (this.windowHeight - 90) * 0.5;
      this.typeChangeDownShowHeight = (this.windowHeight - 90) * 0.5;
    },
    handleEvent(data) {
      //this.$router.push({ name: "liveInfoControl", query: { eventId: eventId } });
      if(!this.$store.state.store.isAdmin && data.managerDepartmentId != this.$store.state.store.departmentId){
        if(data.isAuth == true && data.authCount == 0) {
          alert(this.$t("user-not-permission"));
          return;
        }
      }
      document.location.href = `/${this.accountId}/eventControl/${data.eventId}/SkyView`;

      //Site Control 새창
      /*this.routeUrl = this.$router.resolve({ path: `/${this.accountId}/eventControl/${data.eventId}/SkyView` });
      newTab(this.routeUrl.href, `SkyView_${data.eventId}`);*/
    },
    /*드론 운영 목록*/
    async getDeviceLogList(){
      let params = {
        /*eventId : this.eventId,*/
        pageSize : 5,
        pageNumber : this.currentPage,
        keyword : this.keyword,
        keywordType : this.keywordType,
        isStartTime : true,
        isEndTime : true,
      };
      if(this.startYmd != null && this.endYmd != null) {
        params["startDateRange.from"] = moment(this.startYmd).format("YYYY-MM-DD");
        params["startDateRange.to"] = moment(this.endYmd).format("YYYY-MM-DD");
      }
      fetchDeviceList(params).then((res) => {
        if (res.data.result == 0) {
          this.deviceList = res.data.data.content
          this.deviceTotal = res.data.data.total
        }
      });

    },

    getWorkerStatisticsList() {
      let params = {
        dateRangeValue: 7,
      };

      var chartDate= []
      fetchStatisticsWorkerList(params).then((res) => {
        if(res.data.data.reference != null) {
          res.data.data.reference.forEach((item) => {
            chartDate.push(this.customFormatter(item.date));
            this.chartSupport.push(item.totalData);
          });
        }

      });
    },
    getEventStatisticsList() {
      let params = {
        dateRangeValue: 7,
      };

      fetchStatisticsEventList(params).then((res) => {
        if(res.data.data.reference != null) {
          res.data.data.reference.forEach((item) => {
            this.chartEvent.push(item.totalData);
          });
        }
      });
    },
    getDeviceStatisticsList() {
      let params = {
        dateRangeValue: 7,
      };

      fetchStatisticsDeviceList(params).then((res) => {
        if(res.data.data.reference != null) {
          res.data.data.reference.forEach((item) => {
            this.chartDevice.push(item.device);
          });
        }
      });
    },


    customFormatter(date) {
      return moment(date).format("MM-DD");
    },

    getEventList() {
      let params = {
        pageNumber: 0,
        statuses: "Open",
      };

      fetchEventList(params).then((res) => {
        this.eventList = res.data.data.content;
        this.eventTotal = res.data.data.total;
      });
    },

    handlePop() {
      this.index = this.index + 1;
      window.open(
          `/${this.accountId}/dashboard/overall?new=true`,
          `overallNewWindow${this.index}`,
          "left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no"
      );
    },
    handleRefresh() {
      this.callFunctionsWhenMounted();
    },
    getNoticeAppList(){
      let params = {
          pageNumber: this.noticeAppCurrentPage,
          pageSize: this.noticeAppPageSize,
          searchDate: moment(new Date()).format("YYYY-MM-DD"),
          status: "Used",
      };
      fetchNoticeAppList(params).then((res)=>{
          this.noticeAppList = res.data.data.content;
          this.noticeAppTotal = res.data.data.total;
      });
    },
    getVersionAppLogList(){
      let params = {

      };
      fetchVersionAppLogList(params).then((res)=>{
        this.versionAppLogList = res.data.data.content;
        this.versionAppLogTotal = res.data.data.total;
      });
    },
    getVodList() {
      let params = {
        pageNumber: 0,
        pageSize: 4,
        statuses: "",
        eventStatus : "",
        deviceType : ""
      };
      fetchVodList(params).then((res) => {
        this.vodTotal = res.data.data.total;
        //this.vodList = res.data.data.content;
      });
    },
    getLiveLogCount(){
      let params = {
        pageNumber: this.currentPage,
        pageSize: this.size,
        keywordType: this.keywordType,
        keyword: this.keyword,
      };

      fetchChannelAssignList(params).then((res) => {
        if (res.data.result == 0) {
          this.liveTotal = res.data.data.total;

        } else {
          let message = res.data.data.message;
          alert(message);
        }
      });
    },
    async callFunctionsWhenMounted(){
      await this.getDeviceLogList();
      this.getEventList();
      this.getWorkerStatisticsList();
      this.getEventStatisticsList();
      this.getDeviceStatisticsList();
      this.getNoticeAppList();
      this.getVersionAppLogList();
      this.getVodList();
      this.getLiveLogCount();
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
